/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductCode } from "./productCode";
import { ProviderAccountStatusChangeEvent } from "./providerAccountStatusChangeEvent";

export interface PaymentProvider {
  account_id: string;
  /**
   * The first date the application was active. If an account is disabled and then re-activated this date will not change
   */
  active_at?: string;
  billing_owner: PaymentProvider.BillingOwnerEnum;
  /**
   * Time at which the object was created.
   */
  created_at: string;
  /**
   * The most recent time the account was disabled.
   */
  disabled_at?: string;
  id: string;
  /**
   * The first date the application went into review
   */
  in_review_at?: string;
  payout_owner: PaymentProvider.PayoutOwnerEnum;
  pricing_active_on: string;
  product_code: ProductCode;
  product_code_id: string;
  provider: PaymentProvider.ProviderEnum;
  provider_account_id?: string;
  provider_account_status?: PaymentProvider.ProviderAccountStatusEnum;
  provider_account_status_history?: Array<ProviderAccountStatusChangeEvent>;
  rejected_at?: string;
  /**
   * The first date the application was updated
   */
  started_at?: string;
  status: PaymentProvider.StatusEnum;
  /**
   * The first date the application was submitted
   */
  submitted_at?: string;
  /**
   * The first date the application was submitted to the processor
   */
  submitted_to_processor_at?: string;
  /**
   * Time at which the object was last updated.
   */
  updated_at: string;
  withdrawn_at?: string;
}
export namespace PaymentProvider {
  export type BillingOwnerEnum = "provider" | "tilled";
  export const BillingOwnerEnum = {
    PROVIDER: "provider" as BillingOwnerEnum,
    TILLED: "tilled" as BillingOwnerEnum,
  };
  export type PayoutOwnerEnum = "provider" | "tilled";
  export const PayoutOwnerEnum = {
    PROVIDER: "provider" as PayoutOwnerEnum,
    TILLED: "tilled" as PayoutOwnerEnum,
  };
  export type ProviderEnum =
    | "paysafe"
    | "tsys"
    | "mock"
    | "valor"
    | "handpoint";
  export const ProviderEnum = {
    PAYSAFE: "paysafe" as ProviderEnum,
    TSYS: "tsys" as ProviderEnum,
    MOCK: "mock" as ProviderEnum,
    VALOR: "valor" as ProviderEnum,
    HANDPOINT: "handpoint" as ProviderEnum,
  };
  export type ProviderAccountStatusEnum =
    | "approved"
    | "processing"
    | "deferred"
    | "disabled"
    | "enabled"
    | "pending"
    | "rejected"
    | "returned"
    | "submitted"
    | "waiting"
    | "withdrawn";
  export const ProviderAccountStatusEnum = {
    APPROVED: "approved" as ProviderAccountStatusEnum,
    PROCESSING: "processing" as ProviderAccountStatusEnum,
    DEFERRED: "deferred" as ProviderAccountStatusEnum,
    DISABLED: "disabled" as ProviderAccountStatusEnum,
    ENABLED: "enabled" as ProviderAccountStatusEnum,
    PENDING: "pending" as ProviderAccountStatusEnum,
    REJECTED: "rejected" as ProviderAccountStatusEnum,
    RETURNED: "returned" as ProviderAccountStatusEnum,
    SUBMITTED: "submitted" as ProviderAccountStatusEnum,
    WAITING: "waiting" as ProviderAccountStatusEnum,
    WITHDRAWN: "withdrawn" as ProviderAccountStatusEnum,
  };
  export type StatusEnum =
    | "created"
    | "started"
    | "submitted"
    | "test"
    | "active"
    | "disabled"
    | "in_review"
    | "rejected"
    | "withdrawn";
  export const StatusEnum = {
    CREATED: "created" as StatusEnum,
    STARTED: "started" as StatusEnum,
    SUBMITTED: "submitted" as StatusEnum,
    TEST: "test" as StatusEnum,
    ACTIVE: "active" as StatusEnum,
    DISABLED: "disabled" as StatusEnum,
    IN_REVIEW: "in_review" as StatusEnum,
    REJECTED: "rejected" as StatusEnum,
    WITHDRAWN: "withdrawn" as StatusEnum,
  };
}
